import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles,
  ThemeProvider,
  CssBaseline,
} from '@material-ui/core';

import { Infra, Shentox } from '../assets/fonts';

import Logo from '../assets/images/svg/logo.svg';

const headingStyles = {
  fontFamily: 'Shentox, Arial',
  fontSize: 32,
  fontWeight: 600,
  lineHeight: 1.24,
  paddingTop: 12,
  paddingBottom: 12,
};

let theme = createMuiTheme({
  shadows: ['none'],
  palette: {
    divider: 'rgba(0, 0, 0, 0.05)',
  },
  typography: {
    fontFamily: 'Infra, Arial',
    fontSize: 18,
    h3: { ...headingStyles },
    h4: { ...headingStyles },
    body1: {
      fontSize: 17,
      fontWeight: 600,
      paddingBottom: 8,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 600,
      color: '#858585',
      paddingBottom: 12,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [...Shentox, ...Infra],
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9999 !important',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(themes => ({
  root: {
    padding: themes.spacing(4),
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  logo: {
    position: 'absolute',
    top: 10,
    left: 30,
    display: 'block',
    maxWidth: 200,

    '& svg': {
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      height: 36,
      width: 'auto',
    },
  },
  shop: {
    position: 'absolute',
    top: 10,
    right: 40,
    display: 'block',
    maxWidth: 200,
    textDecoration: 'none',
    color: 'black',
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Link to="/" className={classes.logo}>
        <Logo />
      </Link>

      <Link to="https://www.lockerroomteamstore.com" className={classes.shop}>
        <h3>Shop</h3>
      </Link>

      <main>
        {children}
      </main>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
